import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import Cookie from 'js-cookie';
import { AuthApi } from '../api/auth.api';
import { useAuthContext } from '../contexts';


export const useAuthenticated = () => {
  const { isAuthenticated } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    const refetchAccessToken = async () => {
      try {
        const refreshToken = Cookie.get('refresh_token');
        const accessToken = await AuthApi.refreshAccessToken(refreshToken);
        if (!accessToken) {
          router.push('/?auth-step=login');
        }
      } catch (error) {
        console.error(error);
        router.push('/?auth-step=login');
      }
    };
    if (!isAuthenticated) {
      refetchAccessToken();
    }
  }, [isAuthenticated, router]);
};
