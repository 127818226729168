import React from 'react';
import { ErrorPage } from './error-page.component';

export const ServerErrorPage = () => (
  <ErrorPage>
    <h3>
      Ошибка сервера: Когда технологический танец сталкивается с неожиданным
      сбоем
    </h3>
    <p>
      Что-то пошло не так на нашей стороне. Наши технические специалисты уже
      работают над исправлением проблемы. Пожалуйста, повторите попытку
      позже или свяжитесь с нашей службой поддержки
    </p>
  </ErrorPage>
);
