import useTranslation from 'next-translate/useTranslation';
import { formatPrice } from '@/modules/shared/helpers';

interface FormatDataPriceProps {
  priceType?: string;
  price: number | null,
}

export const useFormatDataPrice = () => {
  const { t } = useTranslation();

  const priceDisplayFormat = ({ priceType = '', price = null } : FormatDataPriceProps) => {
    const mapTypeToName = {
      for_free: t('common:forFree'),
      by_agreement: t('common:byAgreement'),
    };

    if (priceType === 'for_money' && price !== null) {
      return formatPrice({ price });
    }

    return mapTypeToName[priceType] || '';
  };

  return priceDisplayFormat;
};
