'use client';

import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';

let heic2any;
const loadHeic2any = async () => {
  const lib = await import('heic2any');
  heic2any = lib.default || lib;
};
export const useConvertHeicToPng = () => {
  const { locale } = useRouter();

  useEffect(() => {
    loadHeic2any();
  }, []);

  const convertHeicToPng = useCallback(async (file: File): Promise<File> => {
    try {
      let pngBlob = await heic2any({
        blob: file,
        toType: 'image/png',
      });

      if (Array.isArray(pngBlob)) {
        // eslint-disable-next-line prefer-destructuring
        pngBlob = pngBlob[0];
      }

      const pngFile = new File(
        [pngBlob],
        file.name.replace('.heic', '.png'),
        { type: 'image/png' },
      );

      return pngFile;
    } catch (error) {
      console.log('Произошла ошибка при конвертации:', error);
      toast.error(i18n.Form.errors.wrongImgFormat[locale]);
      return null;
    }
  }, []);

  return convertHeicToPng;
};
