import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { kebabCase } from 'lodash';
import { modalTypeParam } from '@/modules/auth/consts';
import { useCleanUrl } from './use-clean-url.hook';


export const useOpenAuthModal = () => {
  const router = useRouter();
  const url = useCleanUrl();

  return useCallback((modalType: string) => {
    const { pathname, search } = url;
    const updatedSearch = new URLSearchParams(search);
    updatedSearch.set(modalTypeParam, kebabCase(modalType));
    router.push(`${pathname}?${updatedSearch.toString()}`);
  }, [router, url]);
};
