import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { AuthProtectedLink } from '@/modules/auth';
import s from '@/styles/layout/Mobile.module.scss';
import Link from 'next/link';
import { Icons } from './icons.types';

export const Route = (icon: Icons) => {
  const {
    path, active, isAuthProtected, defaultIcon, text,
  } = icon;
  const router = useRouter();
  const iconPath = useMemo(
    () => (router.route === path ? active : defaultIcon),
    [router.route],
  );

  return isAuthProtected ? (
    <AuthProtectedLink href={path} className={`${text ? s.plus : ''}`}>
      {text || <img src={iconPath} alt={path} />}
    </AuthProtectedLink>
  ) : (
    <Link href={path}>
      {text || <img src={iconPath} alt={path} />}
    </Link>
  );
};
