import React, { useCallback, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import s from '@/styles/layout/Header.module.scss';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import useUserAccount from '@/hooks/users/useUserAccount';
import { AuthProtectedLink } from '@/modules/auth';
import CategoryButton from '../categories/CategoryButton';
import UserDropdown from './UserDropdown';
import LanPanel from './LanPanel';
import Cartaz from './Buttons/Cartaz';
import AddAdv from './Buttons/AddAdv';
import LocationPan from './LocationPan';
import SearchInput from './Inputs/SearchInput';

export default function Header() {
  const { userData } = useUserAccount();
  const [isLocationModal, setIsLocationModal] = useState(false);
  const dropdown = useRef(null);
  const buttonref = useRef(null);
  const router = useRouter();

  useOutsideClick(dropdown, buttonref, () => {
    if (isLocationModal) {
      setIsLocationModal(false);
    }
  });

  const handleOpenLocation = () => {
    setIsLocationModal(!isLocationModal);
  };

  const onLogoClick = useCallback((event) => {
    event.preventDefault();
    const { locale } = router;
    const { pathname } = window.location;
    if (pathname !== `/${locale}` && pathname !== '/') {
      router.push(`/${locale}`);
    } else {
      window.location.reload();
    }
  }, [router]);

  return (
    <div className={s.header}>
      <header className="container">
        <div className={s.header_container}>
          <div className={s.logo}>
            <Link href={`/${router.locale || 'en'}`} onClick={onLogoClick}>
              <img
                src="/assets/icons/logo2.svg"
                alt="Logo Bazaar"
                className={s.logo_img}
              />
            </Link>
            <LanPanel />
            <LocationPan
              buttonref={buttonref}
              dropdown={dropdown}
              state={isLocationModal}
              isOpen={handleOpenLocation}
            />
          </div>
          <nav className={s.header_nav}>
            <CategoryButton />
            <Cartaz />
            <AddAdv />
          </nav>
          <div style={{ display: 'flex', gap: '12px' }}>
            <SearchInput />
            {userData ? (
              <UserDropdown userData={userData} />
            ) : (
              <AuthProtectedLink href="/?auth-step=login" className={s.login}>
                <img src="/assets/icons/user.svg" alt="user" />
              </AuthProtectedLink>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}
