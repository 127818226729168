import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useCategories } from '@/modules/categories';
import styles from '@/styles/shared/CategoryListMobile.module.scss';

const CategoryListMobil = ({ onClose, innerRef }) => {
  const { categories } = useCategories();
  const router = useRouter();
  const { query } = useRouter();
  const queryCategory = query.category;

  const [showMainCategories, setShowMainCategories] = useState(true);

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(() => {
    const initialIndex = categories.findIndex((el) => el.codename === queryCategory);
    return initialIndex !== -1 ? initialIndex : 0;
  });
  const activeCategory = categories[activeCategoryIndex];

  const handleSaveActiveCategoryIndex = (index) => {
    setActiveCategoryIndex(index);
    setShowMainCategories(false);
  };

  const handleCategoryClick = (category) => {
    router.replace(category.pathname);
    onClose();
  };

  if (!categories.length) {
    return null;
  }

  return (
    <section className={styles.category_list_container} ref={innerRef}>
      <div className={styles.category_list_block}>
        {showMainCategories && (
          <div className={styles.category_btns}>
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleSaveActiveCategoryIndex(index)}
                className={
                  activeCategory.codename === category.codename
                    ? styles.active_btn
                    : styles.not_active_btn
                }
              >
                <img
                  src={category.icon}
                  alt={category.title}
                />
                {category.title}
              </button>
            ))}
          </div>
        )}

        {!showMainCategories && (
          <div className={styles.category_lists}>
            <h2 onClick={() => handleCategoryClick(activeCategory)}>
              {activeCategory.title}
            </h2>

            <div className={styles.sub_categories_block}>
              {categories[activeCategoryIndex].sub_categories.map(
                (subCategory, idx) => (
                  <div key={idx} className={styles.sub_categories}>
                    <h3
                      onClick={() => handleCategoryClick(subCategory)}
                    >
                      {subCategory.title}
                    </h3>
                  </div>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CategoryListMobil;
