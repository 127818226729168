import { useCallback } from 'react';
import { events, providers } from './providers.const';
import { TrackEvent } from './event-tracking.types';



export const useTrackEvent = () => useCallback<TrackEvent>((eventName, options = {}) => {
  for (const { name, track } of providers) {
    if (!(options.exclude?.indexOf(name) > -1) && events[eventName][name]) {
      try {
        track(events[eventName][name], options.payload);
      } catch (err) {
        console.error(`Failed to send event ${eventName} for provider ${name}`);
        console.error(err);
      }
    }
  }
}, []);
