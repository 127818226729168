import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import axios from 'axios';

const AdvDataContext = createContext();

export function AdvDataProvider({ children }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const URL = process.env.NEXT_PUBLIC_API_URL;

  useEffect(() => {
    axios
      .get(`${URL}/api/announcement/list/?page_size=12`)
      .then((response) => {
        setData(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [URL]);

  return (
    <AdvDataContext.Provider value={{ data, loading, error }}>
      {children}
    </AdvDataContext.Provider>
  );
}

export const useAdvData = () => useContext(AdvDataContext);
