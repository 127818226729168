export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);

export const getNextEmailResendKey = (email) => `nextResendEmailTimestamp_${email}`;

export const getNextEmailResendTimestamp = (email) => {
  const nextResendValue = localStorage.getItem(getNextEmailResendKey(email));
  if (!nextResendValue) {
    return getCurrentTimestamp();
  }
  return +nextResendValue;
};

const setActivationEmail = (email) => localStorage.setItem('activationEmail', email);

export const getActivationEmail = () => localStorage.getItem('activationEmail');

export const setNextEmailResendTimestamp = (email) => {
  setActivationEmail(email);
  localStorage.setItem(getNextEmailResendKey(email), String(getCurrentTimestamp() + 60));
};
