import React from 'react';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { ErrorPage } from './error-page.component';

export const NotFoundPage = () => {
  const { locale } = useRouter();

  return (
    <ErrorPage>
      <h3>{i18n.notFoundPage.header[locale]}</h3>
      <p>{i18n.notFoundPage.description[locale]}</p>
    </ErrorPage>
  );
};
