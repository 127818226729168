import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'react-phone-number-input/style.css';
import s from '@/styles/main/AuthModal.module.scss';
import { useAuth } from '@/hooks/auth/useAuth';
import i18n from '@/lib/i18n';
import { InputPassword } from '@/modules/forms/components';
// import { OAuth } from '@/components/uiComp/auth';
import Link from 'next/link';
import { toast } from 'react-toastify';
import { ExampleFormatPhoneNumber } from '@/modules/shared/components';
import { useVisibleErrors } from '@/modules/forms';
import { setNextEmailResendTimestamp } from '@/modules/shared/helpers';
import { useOpenAuthModal } from '../../hooks';
import { AuthContext } from '../../contexts';
import { ErrorMessage } from './error-message.component';
import { ActionButton } from './action-button.component';

export default function RegisterModal() {
  const { locale } = useRouter();
  const forbiddenNames = ['admin', 'administrator', 'root'];
  const { draft, setDraft } = useContext(AuthContext);
  const { t } = useTranslation('');

  const schema = useMemo(() => yup.object().shape({
    fullName: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.name') }))
      .min(2, t('forms:validationErrors.userNameTooShort'))
      .test(
        'is-not-url',
        t('forms:validationErrors.noUrlsUserName'),
        (value) => !/(https?:\/\/|www\.)/.test(value),
      )
      .trim()
      .matches(/^[\w'-\s.А-я]+$/, {
        message: t('forms:validationErrors.invalidCharsUserName'),
        excludeEmptyString: true,
      })
      .matches(/^[^._-].*[^._-]$/, {
        message: t('forms:validationErrors.invalidEdgeCharsUserName'),
        excludeEmptyString: true,
      })
      .notOneOf(forbiddenNames, t('forms:validationErrors.forbiddenName')),
    email: yup.string()
      .email(t('forms:validationErrors.invalidEmail'))
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('forms:validationErrors.invalidEmail')),
    phoneNumber: yup.string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.phoneNumber') }))
      .test('valid-phone', t('forms:validationErrors.invalidPhone'), (value) => {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber ? phoneNumber.isValid() : false;
      }),
    password: yup.string().required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') })),
    password2: yup
      .string()
      .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') }))
      .oneOf([yup.ref('password')], t('forms:validationErrors.passwordsMismatch')),
  })
    .required(), [t, forbiddenNames]);

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: draft,
    mode: 'all',
  });

  const visibleErrors = useVisibleErrors(formState);
  const {
    isSubmitting, isDirty, isValid, errors,
  } = formState;

  const phoneNumber = useWatch({ control, name: 'phoneNumber' });

  useEffect(() => {
    const subscription = watch(() => onSaveFormData());
    return () => subscription.unsubscribe();
  }, [watch]);

  const setModalType = useOpenAuthModal();
  const { register: registerUser } = useAuth();

  const onSaveFormData = useCallback(() => {
    const formData = getValues();
    if (formData) {
      return setDraft(formData);
    }
  }, [setDraft]);

  const handleSendEmailAgain = () => {
    setModalType('resendActivationLink');
  };

  const onSubmit = async (data) => {
    const {
      email,
      fullName,
      phoneNumber,
      password,
      password2,
    } = data;

    const { response, error } = await registerUser(
      email,
      fullName,
      password,
      password2,
      phoneNumber,
      locale,
    );

    if (response) {
      setNextEmailResendTimestamp(email);
      setModalType('registerConfirmation');
      setDraft(null);
    } else {
      toast.error(i18n.auth.register.errors.occurred[locale]);
    }

    if (error?.message?.includes?.('EMAIL_ALREADY_IN_USE')) {
      setError('email', {
        type: 'manual',
        message: t('forms:validationErrors.userEmailExists'),
      });
    }
    if (error && error.message && error.message.includes('user with this phone number already exists')) {
      setError('phoneNumber', {
        type: 'manual',
        message: t('forms:validationErrors.userPhoneExists'),
      });
    }
  };

  return (
    <div className={s.auth_modal}>
      <div className={s.register}>
        <h2>{t('auth:register.title')}</h2>
        <form className={s.register_form} onSubmit={handleSubmit(onSubmit)}>
          <div className={s.register_form_wrapper}>
            <div>
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder={t('auth:label.name')}
                    className={s.input}
                    {...field}
                  />
                )}
              />
              <ErrorMessage error={errors?.fullName} />
            </div>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    placeholder={t('auth:label.email')}
                    className={s.input}
                    {...field}
                  />
                )}
              />
              <ErrorMessage error={errors?.email} />
            </div>
            <div>
              <div className={s.phone_input}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      international
                      defaultCountry="TH"
                      placeholder={t('auth:label.phoneNumber')}
                      {...field}
                    />
                  )}
                />
              </div>
              {!visibleErrors.phoneNumber && <ExampleFormatPhoneNumber phoneNumber={phoneNumber} /> }
              <ErrorMessage error={errors?.phoneNumber} />
            </div>
            <div>
              <InputPassword
                control={control}
                name="password"
                placeholder={t('auth:label.password')}
              />
              <ErrorMessage error={errors?.password} />
            </div>
            <div>
              <InputPassword
                control={control}
                name="password2"
                placeholder={t('auth:label.confirmationPassword')}
              />
              <ErrorMessage error={errors?.password2} />
            </div>
          </div>
          <div>
            <ActionButton type="submit" disabled={isSubmitting || (isDirty && !isValid)}>
              {isSubmitting ? t('forms:labels.submitting') : t('auth:register.buttonRegister')}
            </ActionButton>
          </div>
          {/* <OAuth /> */}
          <div className={s.agreement_text}>
            <div className={s.register_form_sendAgain}>
              <p onClick={handleSendEmailAgain}>{ t('auth:label.sendEmailAgain')}</p>
            </div>
            <h6>
              {i18n.auth.agreement.description[locale]}
              <Link href="/bazaar/rules" target="_blank">
                {i18n.auth.agreement.terms[locale]}
              </Link>
              {i18n.auth.agreement.and[locale]}
              <Link href="/politics" target="_blank">
                {i18n.auth.agreement.policy[locale]}
              </Link>
            </h6>
          </div>
        </form>
      </div>
      {/* {isRegistered && (
        <div
          id="bazaar-google-ad-element-id"
          className="bazaar-google-ad-element-class"
          style={{ display: 'none' }}
        />
      )} */}
    </div>
  );
}
