import { QueryFunction, useQuery, QueryKey } from '@tanstack/react-query';
import { useMemo } from 'react';

type GetEntityApi<
  Entity,
  EndpointParams extends Record<string, unknown> = Record<string, unknown>,
> = (params?: EndpointParams) => Promise<Entity>;


const fetchEntityFactory = <
  Entity,
  EndpointParams extends Record<string, unknown> = Record<string, unknown>,
>(getEntityApi: GetEntityApi<Entity, EndpointParams>) => {
  const queryFn: QueryFunction<
  Entity,
  [EndpointParams, ...QueryKey]
  > = ({ queryKey }) => getEntityApi(queryKey[0]);

  return queryFn;
};

export const entityQueryFactory = <Entity, EndpointParams extends Record<string, unknown> = Record<string, unknown>>(
  getEntityApi: GetEntityApi<Entity, EndpointParams>,
  queryKey: QueryKey,
) => {
  const useEntityQuery = (
    options?: EndpointParams & { enabled?: boolean } & { refetchOnMount?: boolean },
    localQueryKey: QueryKey = [],
  ) => {
    const { enabled = true, refetchOnMount = true, ...endpointParams } = options;

    const {
      data,
      isFetching,
      isPending,
      error,
      refetch,
    } = useQuery({
      queryKey: [endpointParams as EndpointParams, ...queryKey, ...localQueryKey],
      enabled,
      queryFn: fetchEntityFactory(getEntityApi),
      refetchOnWindowFocus: false,
      refetchOnMount,
    });


    return useMemo(() => ({
      initialLoading: isPending,
      isLoading: isFetching,
      error,
      refetch,
      data,
    }), [
      refetch,
      data,
      isPending,
      isFetching,
      error,
    ]);
  };

  return useEntityQuery;
};
