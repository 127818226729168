import { useMemo } from 'react';
import { UrlObject } from 'url';
import { modalTypeParam, redirectAfterLoginParam } from '@/modules/auth/consts';
import { useCleanUrl } from './use-clean-url.hook';

export const useAuthModalUrl = (redirectAfterLoginUrl: string | UrlObject) => {
  const url = useCleanUrl();

  return useMemo(() => {
    const updatedSearch = new URLSearchParams(url.search);
    updatedSearch.set(modalTypeParam, 'login');
    const needToRedirect = !(redirectAfterLoginUrl as string).includes(modalTypeParam);

    if (needToRedirect) {
      updatedSearch.set(redirectAfterLoginParam, encodeURIComponent(redirectAfterLoginUrl.toString()));
    }

    return `${url.pathname}?${updatedSearch.toString()}`;
  }, [url, redirectAfterLoginUrl]);
};
