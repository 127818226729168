import React, {
  createContext, PropsWithChildren, useCallback, useContext, useMemo,
} from 'react';
import { useCategoryName } from '../hooks/use-category-name';
import { useCategoryPath } from '../hooks/use-category-path';
import { useCategoriesListQuery } from '../queries';
import {
  Category, CategoryResponse, SubCategory, UnderSubCategory,
} from '../types';


export const CategoriesContext = createContext<{
  /**
   * @deprecated Используйте import { useCategories } from '@/modules/categories';
   */
  categories: CategoryResponse[];
  enhancedCategories: Category[]
  getCategoryByCode:(...codenames: string[]) => Category | SubCategory | UnderSubCategory | undefined,
  isLoading: boolean;
  error?: Error;
}>({
      categories: [],
      getCategoryByCode: () => undefined,
      enhancedCategories: [],
      isLoading: false,
    });


type CategoriesProviderProps = PropsWithChildren<{
  initData?: CategoryResponse[]
}>;

const getMapKeyByCodenames = (...codenames: string[]) => codenames.join('/');

export function CategoriesProvider({ children, initData }: CategoriesProviderProps) {
  const { data, isLoading, error } = useCategoriesListQuery(initData && {
    staleTime: Infinity,
  }, initData && {
    data: initData,
  });
  const [getNameByCode] = useCategoryName();
  const [getPathByCode] = useCategoryPath();

  const categoriesResponse = data.data as CategoryResponse[];

  const categories = useMemo(
    () => categoriesResponse.map((item) => ({
      id: item.id,
      codename: item.codename,
      icon: item.category_icon,
      title: getNameByCode(item.codename),
      pathname: getPathByCode(item.codename),
      sub_categories: item.sub_categories.map((subItem) => ({
        id: subItem.id,
        codename: subItem.codename,
        title: getNameByCode(item.codename, subItem.codename),
        pathname: getPathByCode(item.codename, subItem.codename),
        under_sub_categories: subItem.under_sub_categories.map(
          (underSubItem) => ({
            id: underSubItem.id,
            codename: underSubItem.codename,
            title: getNameByCode(item.codename, subItem.codename, underSubItem.codename),
            pathname: getPathByCode(item.codename, subItem.codename, underSubItem.codename),
          }),
        ),
      })),
    })),
    [categoriesResponse, getNameByCode, getPathByCode],
  );

  const categoriesMap = useMemo(() => {
    const map = new Map<string, Category | SubCategory | UnderSubCategory>();

    categories.forEach((category) => {
      map.set(getMapKeyByCodenames(category.codename), category);

      category.sub_categories.forEach((subCategory) => {
        map.set(getMapKeyByCodenames(category.codename, subCategory.codename), subCategory);

        subCategory.under_sub_categories.forEach((underSubCategory) => {
          map.set(getMapKeyByCodenames(category.codename, subCategory.codename, underSubCategory.codename), underSubCategory);
        });
      });
    });
    return map;
  }, [categories]);


  const getCategoryByCode = useCallback(
    (...codenames: string[]): Category | SubCategory | UnderSubCategory | undefined => (
      categoriesMap.get(getMapKeyByCodenames(...codenames))
    ),
    [categoriesMap],
  );

  const value = useMemo(
    () => ({
      categories: categoriesResponse,
      enhancedCategories: categories,
      getCategoryByCode,
      isLoading,
      error,
    }),
    [categoriesResponse, categories, getCategoryByCode, isLoading, error],
  );

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  );
}


/**
 * @deprecated Используйте import { useCategories } from '@/modules/categories';
 */
export const useCategoriesContext = () => useContext(CategoriesContext);
