import React, {
  useCallback, useRef, useState,
} from 'react';
import s from '@/styles/main/InputsModal.module.scss';
import { useRouter } from 'next/router';
import { useCityPrefix } from '@/context/CityPrefixContext';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import i18n from '@/lib/i18n';
import SearchModal from './SearchModal';

export default function SearchInputModal() {
  const [inputActive, setInputActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const router = useRouter();
  const { cityPrefix } = useCityPrefix();
  const outside = useRef();
  const inside = useRef();
  const { locale } = useRouter();

  useOutsideClick(outside, inside, () => {
    if (inputActive) {
      setInputActive(false);
    }
  });

  const handleInputActive = () => {
    setInputActive(!inputActive);
    if (!inputActive) setSearchValue('');
  };

  const handleSearch = useCallback(() => {
    if (searchValue.trim()) {
      router.push(`/${cityPrefix}/catalog/all?search=${searchValue}`);
      setInputActive((current) => !current);
      setSearchValue('');
    }
  }, [setInputActive, searchValue, cityPrefix, router]);

  const handlePress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }, [handleSearch]);

  return (
    <div>
      <button onClick={handleInputActive} className={s.search_icon}>
        <img src="/assets/icons/search2.svg" alt="search" />
      </button>
      {inputActive && (
        <div className={s.modal}>
          <div className={s.modal_content} ref={outside}>
            <div className={s.input}>
              <div className={s.inputbox}>
                <input
                  placeholder={i18n.header.search[locale]}
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handlePress}
                />
                <img src="/assets/icons/search2.svg" alt="search" />
              </div>
              <SearchModal
                value={searchValue}
                onClose={handleInputActive}
                onSearch={handleSearch}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
