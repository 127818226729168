import React, { useMemo, useState } from 'react';
import Cookie from 'js-cookie';
import { AuthModal } from '../components';
import { useLogout, useWatchAuthTokens } from './auth.hooks';
import { useLoginMutation } from './auth.mutations';
import { AuthContext, IAuthContext } from '../contexts';

type FormValue = string | number;

export function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState(Cookie.get('access_token') || '');
  const [refreshToken, setRefreshToken] = useState(Cookie.get('refresh_token') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState<Record<string, FormValue> | null>(null);
  useWatchAuthTokens(accessToken, setAccessToken, refreshToken, setRefreshToken, setIsLoading);

  const logout = useLogout();
  const {
    login,
    isLoading: isLoggingIn,
    error: loginError,
    resetLoginError,
  } = useLoginMutation(setRefreshToken, setAccessToken);
  const value = useMemo<IAuthContext>(
    () => ({
      isLoading: isLoading || isLoggingIn,
      isAuthenticated: !!accessToken,
      login,
      logout,
      loginError: loginError as Error | undefined,
      draft,
      setDraft,
      resetLoginError,
    }),
    [isLoading, accessToken, logout, isLoggingIn, login, loginError, draft, resetLoginError],
  );

  return (
    <AuthContext.Provider value={value}>
      <>
        <AuthModal />
        {children}
      </>
    </AuthContext.Provider>
  );
}
