import { useEffect } from 'react';

function useOutsideClick(ref, buttonRef, callback) {
  const handleClick = (e) => {
    if (buttonRef.current && buttonRef.current.contains(e.target)) {
      // If the button was clicked, do nothing
      return;
    }
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, buttonRef, callback]);

  return { ref, buttonRef, handleClick };
}

export default useOutsideClick;
