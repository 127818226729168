import { entityQueryFactory } from '@/modules/shared/queries';
import { LocationsApi } from '../api/locations.api';


const _useMyLocationQuery = entityQueryFactory(LocationsApi.getLocation, ['my-location']);

export const useMyLocationQuery = (location) => _useMyLocationQuery({
  enabled: !!location,
  ...location,
});


