import React, {
  useCallback, useRef, useState,
} from 'react';
import s from '@/styles/main/Inputs.module.scss';
import { useRouter } from 'next/router';
import { useCityPrefix } from '@/context/CityPrefixContext';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import i18n from '@/lib/i18n';
import SearchInputModal from './SearchInputModal';
import SearchModal from './SearchModal';


export default function SearchInput() {
  const [inputActive, setInputActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const router = useRouter();
  const { cityPrefix } = useCityPrefix();
  const outside = useRef();
  const inside = useRef();
  const { locale } = useRouter();

  useOutsideClick(outside, inside, () => {
    if (inputActive) {
      setInputActive(false);
    }
  });

  const handleInputActive = () => {
    setInputActive(!inputActive);
    if (!inputActive) setSearchValue('');
  };

  const handleSearch = useCallback(() => {
    if (searchValue.trim()) {
      router.push(`/${cityPrefix}/catalog/all?search=${searchValue}`);
      setInputActive((current) => !current);
      setSearchValue('');
    }
  }, [setInputActive, searchValue, cityPrefix, router]);

  const handlePress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }, [handleSearch]);

  return (
    <div>
      <div className={s.mobile_dn}>
        <SearchInputModal />
      </div>
      <div
        className={inputActive ? s.active_search_block : s.search_block}
        ref={outside}
      >
        <button className={s.search_icon} onClick={handleInputActive} type="button">
          <img src="/assets/icons/search2.svg" alt="search" />
        </button>
        <input
          placeholder={i18n.header.search[locale]}
          type="text"
          className={`${s.search_input} ${
            inputActive ? s.search_input_active : ''
          }`}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handlePress}
        />
        {inputActive && (
          <SearchModal
            value={searchValue}
            onClose={handleInputActive}
            onSearch={handleSearch}
          />
        )}
      </div>
    </div>
  );
}
