import { createContext, useContext } from 'react';
import { noop } from 'lodash';

type FormValue = string | number;

export interface IAuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  loginError?: Error;
  login: (loginData: { email: string, password: string }) => void;
  logout: () => void;
  draft: Record<string, FormValue> | null;
  setDraft: (draftData: Record<string, FormValue> | null) => void;
  resetLoginError: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  login: noop,
  logout: noop,
  draft: null,
  setDraft: noop,
  resetLoginError: noop,
});


export const useAuthContext = () => useContext(AuthContext);
