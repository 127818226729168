import React, { FC, useState } from 'react';
import s from './checkbox.module.scss';
import { SvgIcon } from '../svg-icon';


interface ICheckbox {
  id?: string;
  defaultValue: boolean;
}
export const Checkbox: FC<ICheckbox> = ({ id, defaultValue = false }) => {
  const [checked, setChecked] = useState(defaultValue);

  return (
    <span className={s.checkbox}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
        className={s.checkbox_input}
      />
      <span className={checked ? s.active : s.inActive}>
        {checked && <SvgIcon name="check" />}
      </span>
    </span>
  );
};
