import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import s from '@/styles/main/AuthModal.module.scss';
import useForgotPassword from '@/hooks/auth/useForgotPassword';
import Cookie from 'js-cookie';
import { useVisibleErrors } from '@/modules/forms';
import { useOpenAuthModal } from '../../hooks';
import { ActionButton } from './action-button.component';


export const ForgotPasswordModal = () => {
  const { t } = useTranslation('');
  const schema = yup
    .object()
    .shape({
      email: yup.string()
        .email(t('forms:validationErrors.invalidEmail'))
        .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('forms:validationErrors.invalidEmail')),
    })
    .required();

  const {
    register, handleSubmit, setError, formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all', // recalculate errors on change/blur/submit/touch
  });
  const visibleErrors = useVisibleErrors(formState);
  const {
    isSubmitting, isDirty, isValid, errors,
  } = formState;

  const { setEmail, sendActivationCode } = useForgotPassword();
  const setModalType = useOpenAuthModal();
  const onSubmit = handleSubmit(async (data) => {
    try {
      setEmail(data.email);
      await sendActivationCode(data.email);
      Cookie.set('forgotPasswordEmail', data.email);
      setModalType('reset');
    } catch (error) {
      if (error.response.data.validation_errors.email.error_code.includes('USER_DOESNT_EXIST')) {
        setError('email', {
          type: 'manual',
          message: t('forms:validationErrors.emailNotRegistered'),
        });
      }
    }
  });

  return (
    <div className={s.auth_modal}>
      <div className={s.forgot_password}>
        <h2>{t('auth:forgotPassword.title')}</h2>
        <p className={s.forgot_password_description}>{t('auth:label.enterEmail')}</p>
        <form className={s.forgot_form} onSubmit={onSubmit}>
          <div>
            <input
              {...register('email')}
              placeholder="Email"
              className={isSubmitting ? s.disabled : ''}
            />
            {(visibleErrors.email || errors) && <p className={s.error}>{errors.email?.message}</p>}
          </div>
          <ActionButton type="submit" disabled={isSubmitting || (isDirty && !isValid)}>
            {isSubmitting ? t('forms:labels.submitting') : t('forms:labels.send')}
          </ActionButton>
        </form>
      </div>
    </div>
  );
};
