import React from 'react';
import s from '@/styles/main/Buttons.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { AuthProtectedLink } from '@/modules/auth';

export default function AddAdv() {
  const router = useRouter();

  return (
    <AuthProtectedLink href={`/${router.locale}/product/create`} className={s.add_adv}>
      <img src="/assets/icons/add.svg" alt="add" />
      <span>{i18n.header.createAdv[router.locale]}</span>
    </AuthProtectedLink>
  );
}
