import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import { getCookie } from '@/components/cookie/cookieHelpers';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useMeContext } from '@/modules/me/providers';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const useUserAccount = () => {
  const [loading, setLoading] = useState(false);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [error, setError] = useState(null);
  const { locale } = useRouter();

  // as for now keep using useUserAccount,
  // but in future completely migrate the functionality to MeProvider
  const { me, isLoading: isFetchingUserData, refetch } = useMeContext();
  const [userData, setUserData] = useState(me);
  useEffect(() => {
    setUserData(me);
  }, [me]);

  const updateUser = async (updateData) => {
    try {
      setUpdateUserLoading(true);
      const token = getCookie('access_token');
      const response = await axios.patch(
        `${API_URL}/api/users/profile/`,

        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response.status === 200) {
        toast.success(i18n.toast.Successfully[locale]);
        await refetch();
      }
    } catch (err) {
      setError(err);
    } finally {
      setUpdateUserLoading(false);
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      const token = Cookie.get('access_token');

      await axios.delete(`${API_URL}/api/announcement/delete/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedAnnouncements = userData.not_active_announcements.filter(
        (announcement) => announcement.id !== id,
      );
      setUserData((prevData) => ({
        ...prevData,
        not_active_announcements: updatedAnnouncements,
      }));
    } catch (err) {
      console.error('Error deleting announcement:', err);
    }
  };
  const deleteAnnouncementAdmin = async (id) => {
    try {
      const token = Cookie.get('access_token');

      await axios.delete(`${API_URL}/api/admin_panel/del_ann/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedAnnouncements = userData.not_active_announcements.filter(
        (announcement) => announcement.id !== id,
      );
      setUserData((prevData) => ({
        ...prevData,
        not_active_announcements: updatedAnnouncements,
      }));
    } catch (err) {
      console.error('Error deleting announcement:', err);
    }
  };

  const deactivateAnnouncement = async (id) => {
    try {
      const accessToken = getCookie('access_token');

      const response = await axios.patch(
        `${API_URL}/api/announcement/update/${id}/`,
        { is_active: false },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при деактивации!');
      }

      const updatedActiveAnnouncements = userData.announcements.filter(
        (announcement) => announcement.id !== id,
      );
      const updatedNotActiveAnnouncements = userData.not_active_announcements
        .concat([response.data]);
      setUserData((prevData) => ({
        ...prevData,
        announcements: updatedActiveAnnouncements,
        not_active_announcements: updatedNotActiveAnnouncements,
      }));

      toast.success(i18n.toast.Thedeactivated[locale]);
    } catch (err) {
      console.error('Error deactivating announcement:', err);
    }
  };

  const activateAnnouncement = async (id) => {
    try {
      const accessToken = getCookie('access_token');

      const response = await axios.patch(
        `${API_URL}/api/announcement/update/${id}/`,

        { is_active: true },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при активации!');
      }

      const updatedNotActiveAnnouncements = userData.not_active_announcements.filter(
        (announcement) => announcement.id !== id,
      );
      const updatedActiveAnnouncements = userData.announcements.concat([
        response.data,
      ]);
      setUserData((prevData) => ({
        ...prevData,
        announcements: updatedActiveAnnouncements,
        not_active_announcements: updatedNotActiveAnnouncements,
      }));
      refetch();

      toast.success(i18n.toast.Advertisement[locale]);
    } catch (err) {
      console.error('Error activating announcement:', err);
    }
  };

  return {
    loading: loading || isFetchingUserData,
    updateUserLoading,
    error,
    deleteAnnouncement,
    updateUser,
    deactivateAnnouncement,
    activateAnnouncement,
    deleteAnnouncementAdmin,
    userData,
    refetch,
  };
};

export default useUserAccount;
