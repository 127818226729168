import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useCategories } from '@/modules/categories';

import styles from '@/styles/shared/CategoryList.module.scss';

const CategoryList = ({ onClose, innerRef }) => {
  const { categories } = useCategories();
  const { query } = useRouter();
  const queryCategory = query.category;

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(() => {
    const initialIndex = categories.findIndex((el) => el.codename === queryCategory);
    return initialIndex !== -1 ? initialIndex : 0;
  });
  const activeCategory = categories[activeCategoryIndex];

  const handleSaveActiveCategoryIndex = (index) => {
    setActiveCategoryIndex(index);
  };

  if (!categories.length) {
    return null;
  }

  return (
    <section className={styles.category_list_container} ref={innerRef}>
      <div className={styles.category_list_wrapper}>
        <div className={styles.category_btn}>
          {categories.map((category, index) => (
            <Link
              key={index}
              href={category.pathname}
            >
              <button
                className={
                  activeCategory.codename === category.codename
                    ? styles.active_btn
                    : styles.not_active_btn
                }
                onClick={() => handleSaveActiveCategoryIndex(index)}
              >
                <img
                  src={category.icon}
                  alt={category.title}
                />
                {category.title}
              </button>
            </Link>
          ))}
        </div>
        <div className={styles.category_list}>
          <h2>
            <Link href={activeCategory.pathname}>
              <h3 className={styles.cat} onClick={onClose}>
                {activeCategory.title}
              </h3>
            </Link>
          </h2>
          <div className={styles.sub_categories_list}>
            {activeCategory.sub_categories.map((subCategory, idx) => (
              <p key={idx} className={styles.sub_category_title}>
                <Link
                  href={subCategory.pathname}
                >
                  <h3 className={styles.sub} onClick={onClose}>
                    {subCategory.title}
                  </h3>
                </Link>
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryList;
