import { fetchList } from '@/modules/shared/api';
import { endpoints } from './endpoints';
import { CategoryResponse } from '../types';


const tempDisabledTransportSubCategories = ['rental', 'buy'];


export const CategoriesApi = {
  list: async (config = {}) => {
    const data = await fetchList<CategoryResponse[]>(endpoints.list, config);
    const tempFix = data.map((item) => {
      if (item.codename !== 'vehicles-rental') {
        return item;
      }

      return {
        ...item,
        sub_categories: item.sub_categories
          .filter((subCategory) => !tempDisabledTransportSubCategories.includes(subCategory.codename)),
      };
    });
    return { data: tempFix };
  },
};
