import React, { FC, useEffect, ReactNode } from 'react';
import s from './image-view-modal.module.scss';

interface IUserImageViewModal {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  updateCurrentModalImgIndex: () => void;
}

export const ImageViewModal: FC<IUserImageViewModal> = ({
  isOpen,
  onClose,
  updateCurrentModalImgIndex,
  children,
}) => {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement
        && !event.target.closest('button')
        && !event.target.closest('img')
      ) {
        onClose();
        updateCurrentModalImgIndex();
      }
    };

    const modalContainer = document.querySelector(`.${s.modalContainer}`);
    modalContainer?.addEventListener('click', handleClickOutside);

    return () => modalContainer?.removeEventListener('click', handleClickOutside);
  }, [onClose, updateCurrentModalImgIndex]);

  return <div className={s.modalContainer}>{children}</div>;
};
