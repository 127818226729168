import React, { useMemo } from 'react';
import s from '@/styles/layout/Footer.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useCategories } from '@/modules/categories';

const FOOTER_CATALOG_CODENAMES = [
  [
    'vehicles-rental',
    'services',
    'personal-items',
    'home',
    'spare-parts',
  ],
  [
    'electronics',
    'hobbies',
    'animals',
    'business',
    'real-estate',
  ],
];

export default function Footer() {
  const { locale } = useRouter();
  const { getCategoryByCode } = useCategories();

  const [first, second] = useMemo(
    () => FOOTER_CATALOG_CODENAMES.map((groups) => groups
      .map((codename) => getCategoryByCode(codename))
      .filter((item) => !!item)),
    [getCategoryByCode],
  );

  return (
    <div className={s.footer_block}>
      <footer className={s.footer_container}>
        <div className={s.footer_nav}>
          <Link href="/">
            <img
              src="/assets/icons/logo2.svg"
              alt="bazaar"
            />
          </Link>
          <nav>
            <ul>
              <li className={s.contact_us}>
                <Link href="/support">
                  <button>
                    {i18n.footer.block1.contact[locale]}
                    <img src="/assets/icons/phone.svg" alt="" />
                  </button>
                </Link>
              </li>
              <li>©2023 bazaar</li>
              <li>
                <Link href="/bazaar/about">{i18n.footer.about[locale]}</Link>
              </li>
              <li>
                <Link href="/bazaar/rules">{i18n.footer.rules[locale]}</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={s.footer_nav}>
          <nav>
            <ul>
              {first.map((item) => (
                <Link key={item.id} href={item.pathname}>
                  <li>{item.title}</li>
                </Link>
              ))}
            </ul>
          </nav>
        </div>
        <div className={s.footer_nav}>
          <nav>
            <ul>
              {second.map((item) => (
                <Link key={item.id} href={item.pathname}>
                  <li>{item.title}</li>
                </Link>
              ))}
            </ul>
          </nav>
        </div>
        <div className={s.social_block}>
          <p>{i18n.footer.social[locale]}</p>
          <div className={s.social}>
            <Link href="https://www.instagram.com/bazaarorigin/" target="_blank">
              <img src="/assets/icons/insta2.svg" alt="instagram" />
            </Link>
            <Link href="https://t.me/bazaarorigin/" target="_blank">
              <img src="/assets/icons/telegram.svg" alt="telegram" />
            </Link>
            <Link href="https://twitter.com/bazaarorigin/" target="_blank">
              <img
                style={{ width: '24px' }}
                src="/assets/icons/newtwitter.svg"
                alt="twitter"
              />
            </Link>
          </div>
          <p className={s.contact_title}>{i18n.footer.contact[locale]}</p>
          <div className={s.contact}>
            <Link href="mailto:support@bazaarorigin.com">
              <div>
                <img src="/assets/icons/mail.svg" alt="email" />
                <span>support@bazaarorigin.com</span>
              </div>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}
