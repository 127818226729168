import React from 'react';
import s from '@/styles/uiComp/SearchModal.module.scss';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';

function SearchModal({ onSearch, onClose }) {
  const { locale } = useRouter();

  const handleSearchClick = () => {
    onSearch();
    onClose();
  };

  return (
    <div className={s.container_search_modal}>
      <div className={s.search_btn}>
        <button onClick={handleSearchClick}>
          {i18n.header.search[locale]}
        </button>
      </div>
    </div>
  );
}

export default SearchModal;
