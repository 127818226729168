import {
  useReducer, useMemo, Dispatch,
} from 'react';
import { useUpdateEffect } from '@react-hookz/web';

const stringOrUndefinedToState = (v: string) => v || undefined;
const booleanToState = (v: string) => Boolean(v);
const arrayToState = (v: string | string[]) => (Array.isArray(v) ? v : v ? [v] : []);


const MAP_QUERY_TO_INITIAL_FIELDS = {
  swimming_pool: booleanToState,
  deposit: booleanToState,
  delivery: booleanToState,
  child_seat: booleanToState,
  phone_mount: booleanToState,
  phone_charger: booleanToState,
  availability_of_loaders: booleanToState,
  audio_system: booleanToState,
  district: stringOrUndefinedToState,
  search: stringOrUndefinedToState,
  color: stringOrUndefinedToState,
  max_price: stringOrUndefinedToState,
  manufacturer: stringOrUndefinedToState,
  min_price: stringOrUndefinedToState,
  city: stringOrUndefinedToState,
  rental_type: arrayToState,
  engine_capacity: arrayToState,
  number_of_rooms: arrayToState,
  type_housing: arrayToState,
  rules: arrayToState,
  drive: arrayToState,
  vehicle_type: arrayToState,
  service_type: arrayToState,
  schedule: arrayToState,
  experience: arrayToState,
  gender: arrayToState,
  payment: arrayToState,
  brand: arrayToState,
  state: arrayToState,
  size: arrayToState,
};

type StateValues = string | string[] | boolean | undefined | null;

type MapQueryFields = typeof MAP_QUERY_TO_INITIAL_FIELDS;

type FilterQuery = keyof MapQueryFields;

type FormState<T extends FilterQuery> = {
  value: Record<T, ReturnType<MapQueryFields[T]>>,
  setter: Record<T, Dispatch<ReturnType<MapQueryFields[T]>>>
  query: Record<T, ReturnType<MapQueryFields[T]> | undefined>,
};

function reducer<T extends FilterQuery>(state, action: {
  type: 'SET_PROPERTY';
  payload: {
    name: T,
    value: StateValues
  };
} | {
  type: 'SET_STATE';
  payload: Record<T, StateValues>;
}) {
  switch (action.type) {
    case 'SET_PROPERTY':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_STATE':
      return { ...action.payload };
    default:
      return state;
  }
}

export const useFilterFormState = <T extends FilterQuery = FilterQuery>(
  fields: T[],
  queryState: Record<string, StateValues> | undefined,
  fetchData?: (query: Record<T, ReturnType<MapQueryFields[T]> | undefined>) => void,
) => {
  const mappedQueryState = useMemo(() => fields.reduce((memo, value) => {
    memo[value] = MAP_QUERY_TO_INITIAL_FIELDS[value]?.(queryState?.[value] as string);
    return memo;
  }, {} as Record<T, StateValues>), [...fields, queryState]);

  const [state, dispatch] = useReducer<typeof reducer<T>>(
    reducer,
    mappedQueryState,
  );

  useUpdateEffect(() => {
    dispatch({ type: 'SET_STATE', payload: mappedQueryState });
  }, [
    mappedQueryState,
    queryState?.category,
    queryState?.sub_category,
    queryState?.under_sub_category,
  ]);

  const form = useMemo(() => Object
    .keys(state)
    .reduce((memo, property) => {
      const setState = (value) => dispatch({ type: 'SET_PROPERTY', payload: { name: property as T, value } });
      const query = Array.isArray(state[property])
        ? (state[property].length ? state[property] : undefined)
        : state[property] || undefined;

      memo.setter[property] = setState;
      memo.query[property] = query;

      return memo;
    }, {
      value: state,
      setter: {},
      query: {},
    } as FormState<T>), [state]);

  useUpdateEffect(() => {
    fetchData?.({
      ...form.query,
      city: form.value['city' as const],
      district: form.value['district' as const],
    });
  }, [form.value['city' as const], form.value['district' as const]]);

  return [form] as const;
};
