import { useEffect, useState } from 'react';
import axios from 'axios';


const API_URL = process.env.NEXT_PUBLIC_API_URL;


export const useLocationData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activateLocations, setActivateLocations] = useState([]);
  useEffect(() => {
    fetchActiveLocations();
  }, []);

  const fetchActiveLocations = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${API_URL}/api/tools/active_cities_and_districts/`,
      );

      setActivateLocations(response.data);
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchActiveLocations,
    activateLocations,
    isLoading,
    error,
  };
};
