import React, { useEffect, useRef, useState } from 'react';
import s from '@/styles/layout/UserDropdown.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import i18n from '@/lib/i18n';
import c from '@/styles/layout/Header.module.scss';
import { useAuthContext } from '@/modules/auth';

export default function UserDropdown({ userData }) {
  const [isModal, setIsModal] = useState(false);
  const router = useRouter();
  const { logout } = useAuthContext();
  const dropdown = useRef(null);
  const { locale } = useRouter();
  const buttonRef = useRef(null);

  useEffect(() => {
    setIsModal(false);
  }, [router.asPath]);

  useOutsideClick(dropdown, buttonRef, () => {
    if (isModal) {
      setIsModal(false);
    }
  });

  const handleModal = () => {
    setIsModal(!isModal);
  };

  return (
    <div className={s.dropdown_block}>
      <div className={s.dropdown} onClick={handleModal} ref={buttonRef}>
        <img src="/assets/icons/user.svg" alt="user" />
      </div>
      {isModal ? (
        <div className={s.modal} ref={dropdown}>
          <div
            onClick={() => router.push(`/${locale}/user/account`)}
            className={s.user_data}
          >
            <img
              src={
                userData?.avatar
                  ? `${userData.avatar}`
                  : '/assets/images/sceleton-ava.png'
              }
              alt="user"
            />
            <div>
              <h3>{userData.full_name}</h3>
              <p>
                {userData.is_admin
                  ? locale === 'en'
                    ? 'Administrator'
                    : 'Администратор'
                  : userData.is_moderator
                    ? locale === 'en'
                      ? 'Moderator'
                      : 'Модератор'
                    : `${i18n.header.dropdown.user[locale]}`}
              </p>
            </div>
          </div>
          <ul>
            <li>
              <img src="/assets/icons/афиша.svg" alt="adv" />
              <Link href={`/${locale}/user/advs`}>
                {i18n.header.dropdown.myAdv[locale]}
              </Link>
            </li>
            <li>
              <img src="/assets/icons/chat.svg" alt="adv" />
              <Link href={`/${locale}/user/chat`}>{i18n.header.dropdown.chat[locale]}</Link>
            </li>
            <li>
              <img src="/assets/icons/posters.svg" alt="posters" />
              <Link href={`/${locale}/user/posters`}>
                {i18n.header.dropdown.myEvents[locale]}
              </Link>
            </li>
          </ul>
          <div className={s.logout}>
            <img src="/assets/icons/logout.svg" alt="logout" />
            <button onClick={logout}>
              {i18n.header.dropdown.logout[locale]}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
