import React, { useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import s from '@/styles/layout/LanPanel.module.scss';
import c from '@/styles/layout/LanPanelModal.module.scss';
import Link from 'next/link';
import setLanguage from 'next-translate/setLanguage';
import { useLanguageMutation, useMeQuery } from '@/modules/me';
import { useAuthContext } from '@/modules/auth';


const languages = [
  { locale: 'en', label: 'EN' },
  { locale: 'ru', label: 'RU' },
];
const getLanguage = (locale) => languages.find((language) => language.locale === locale);


export default function LanPanel() {
  const router = useRouter();
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const outside = useRef();
  const inside = useRef();
  const [isLanguageModal, setIsLanguageModal] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const { mutate: updateUserLanguage } = useLanguageMutation();
  const { isAuthenticated } = useAuthContext();
  const { data: userData } = useMeQuery(isAuthenticated);

  useOutsideClick(outside, inside, () => {
    if (isLanguageModal) {
      setIsLanguageModal(false);
    }
  });

  useEffect(() => {
    if (userData?.locale) {
      const userLanguage = getLanguage(userData?.locale);
      localStorage.setItem('locale', userLanguage?.locale || 'en');
      setSelectedLanguage(userLanguage);
    }

    const languageFromLocalStorage = localStorage.getItem('locale');
    if (!languageFromLocalStorage) {
      const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0];
      const userLanguage = getLanguage(browserLanguage);
      localStorage.setItem('locale', userLanguage?.locale || 'en');
      setSelectedLanguage(userLanguage);
    } else {
      const userLanguage = getLanguage(languageFromLocalStorage);
      if (userLanguage) setSelectedLanguage(userLanguage);
    }
    router.push(router.asPath, undefined, { locale: localStorage.getItem('locale') });
  }, [userData, setSelectedLanguage]);

  const handleChangeLang = async (language) => {
    setSelectedLanguage(language);
    setIsLanguageModal(false);
    setIsFlipped(false);
    localStorage.setItem('locale', language.locale);
    await setLanguage(language.locale);

    if (isAuthenticated) {
      await updateUserLanguage(language.locale);
    }
  };

  return (
    <div className={s.container_language}>
      <button
        type="button"
        className={s.lan_block}
        ref={outside}
        onClick={() => setIsLanguageModal(!isLanguageModal)}
      >
        <span>{selectedLanguage.label}</span>
        <img
          src="/assets/icons/arrow.svg"
          alt="icon_error"
          className={isFlipped ? s.is_active : s.is_not_active}
        />
      </button>
      {isLanguageModal && (
        <div className={c.language_modal} ref={inside}>
          {languages.map((language) => (
            <Link
              key={language.locale}
              href={router.asPath}
              locale={language.locale}
              shallow
              className={c.language_label}
              onClick={() => handleChangeLang(language)}
            >
              <button
                className={c.language_option}
              >
                {language.label}
              </button>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
