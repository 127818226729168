import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';


export const getTokenExpiration = (token: string) => {
  const decoded = jwt.decode(token);

  if (typeof decoded !== 'string' && typeof decoded?.exp === 'number') {
    return new Date(decoded.exp * 1000);
  }

  return undefined;
};


export const cleanCookies = () => {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  Cookies.remove('user_id');
};
