import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useUpdateEffect } from '@react-hookz/web';
import useTranslation from 'next-translate/useTranslation';
import s from '@/styles/main/AuthModal.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '@/lib/i18n';
import Link from 'next/link';
import { InputPassword, InputCheckbox } from '@/modules/forms';
// import { OAuth } from '@/components/uiComp/auth';
import { useMeQuery } from '@/modules/me';
import { useCloseAuthModal, useOpenAuthModal } from '../../hooks';
import { useAuthContext } from '../../contexts';


export const LoginModal = () => {
  const { locale } = useRouter();
  const { t } = useTranslation('');

  const schema = yup
    .object()
    .shape({
      email: yup.string()
        .email(t('forms:validationErrors.invalidEmail'))
        .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('forms:validationErrors.invalidEmail')),
      password: yup.string().required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') })),
    });

  const {
    register, handleSubmit, control, watch, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
  });
  const email = watch('email');
  const {
    login,
    isAuthenticated,
    loginError,
    isLoading: isSubmitting,
    resetLoginError,
  } = useAuthContext();
  const { data: userData } = useMeQuery(isAuthenticated);
  const [error, setError] = useState();

  const closeModal = useCloseAuthModal();
  const setModalType = useOpenAuthModal();

  useUpdateEffect(() => {
    setError(loginError);
  }, [loginError]);

  useEffect(() => {
    const handleLoginError = () => {
      const response = loginError?.response;
      if (!response) {
        return;
      }
      if (response.status === 401 && response.data.error_code === 'USER_NOT_ACTIVE') {
        localStorage.setItem(
          'authModalState',
          JSON.stringify({ modalType: 'registerConfirmation', email }),

        );
        setModalType('registerConfirmation');
        resetLoginError();
      }
    };

    if (isAuthenticated && userData) {
      localStorage.removeItem('authModalState');
      toast.success(i18n.auth.login.successfully[locale]);
      closeModal();
    } else if (loginError) {
      handleLoginError();
    }
  }, [isAuthenticated, closeModal, locale, loginError, setModalType, email]);

  const onSubmit = (data) => {
    login(data);
  };

  const handleForgotPassword = () => {
    setModalType('forgot');
  };

  return (
    <div className={s.auth_modal}>
      <div className={s.login}>
        <h2>{i18n.auth.login.title[locale]}</h2>

        {!error ? null : (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {i18n.auth.login.errors.incorrect[locale]}
          </div>
        )}

        <form className={s.login_form} onSubmit={handleSubmit(onSubmit)}>
          <div className={s.login_signin_section}>
            <div>
              <input
                placeholder="Email"
                {...register('email')}
              />
              {errors.email && <p className={s.error}>{errors.email.message}</p>}
            </div>
            <div>
              <InputPassword
                control={control}
                name="password"
                placeholder={t('auth:label.password')}
              />
              {errors.password && <p className={s.error}>{errors.password.message}</p>}
            </div>

            <div className={s.action_password}>
              <InputCheckbox
                control={control}
                name="rememberMe"
                label={i18n.auth.remember}
              />
              <button
                type="button"
                onClick={handleForgotPassword}
              >
                {i18n.auth.login.forgot[locale]}
              </button>
            </div>
          </div>

          <button
            className={s.login_button_submit}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? i18n.submiting[locale] : i18n.auth.login.signIn[locale]}
          </button>

          <div className={s.login_options}>
            {/* <OAuth /> */}

            <div className={s.registerr}>
              <h6>
                {i18n.auth.login.questionRegister[locale]}
                <span>Bazaar?</span>
              </h6>
              <button
                type="button"
                onClick={() => setModalType('register')}
                className={s.register_button}
              >
                {i18n.auth.login.register[locale]}
              </button>
            </div>
            <div className={s.agreement_text}>
              <h6>
                {i18n.auth.agreement.description[locale]}
                <Link href="/bazaar/rules" target="_blank">{i18n.auth.agreement.terms[locale]}</Link>
                {i18n.auth.agreement.and[locale]}
                <Link href="/politics" target="_blank">{i18n.auth.agreement.policy[locale]}</Link>
              </h6>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
