// eslint-disable-next-line  import/newline-after-import
import { translateDistrict, translateCity } from '@/lib/translateDistrict';
export { onNetworkError } from './on-network-error.helper';
export { stopEvent } from './stop-event.helper';
export { useConvertHeicToPng } from './use-convert-heic-to-png.helper';
export { generateThumbnail } from './generate-thumbnail';
export { formatPrice } from './format-price.helper';
export { cleanPriceValue } from './clean-price-value.helper';
export { validateInputKeyPress } from './validate-input-key-press.helper';
export { exampleFormatPhoneNumber } from './example-format-phone-number.helper';
export { isServerSide } from './environment';
export {
  getCurrentTimestamp,
  getNextEmailResendKey,
  getNextEmailResendTimestamp,
  getActivationEmail,
  setNextEmailResendTimestamp,
} from './current-timestamp.helpers';
export { cleanDescription } from './clean-description.helper';
export * from './locale';
export { compressImage } from './compress-image.helper';


export const getCityAndDistrict = (locale: string, city?: string, district?: string) => {
  const translatedDistrict = translateDistrict(district, locale) || '';
  const translatedCity = translateCity(city, locale) || '';

  return `${translatedCity}${translatedDistrict ? ', ' : ''}${translatedDistrict}`;
};
