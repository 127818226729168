import React, { useMemo } from 'react';
import s from '@/styles/layout/Mobile.module.scss';
import { useCityPrefix } from '@/context/CityPrefixContext';
import { useRouter } from 'next/router';
import { Route, Icons } from './mobile';

export function Mobile() {
  const { locale } = useRouter();
  const { cityPrefix } = useCityPrefix();

  const paths: Icons[] = useMemo(() => ([
    {
      path: `/${locale}/`,
      active: '/assets/icons/Home-mobile-active.svg',
      defaultIcon: '/assets/icons/Home-mobile.svg',
      isAuthProtected: false,
    },
    {
      path: `/${locale}/user/chat`,
      active: '/assets/icons/chat-mobile-active.svg',
      defaultIcon: '/assets/icons/chat-mobile.svg',
      isAuthProtected: true,
    },
    {
      path: `/${locale}/product/create`,
      text: '+',
      isAuthProtected: true,
    },
    {
      path: `/${locale}/${cityPrefix}/posters`,
      active: '/assets/icons/saved-mobile-active.svg',
      defaultIcon: '/assets/icons/saved-mobile.svg',
      isAuthProtected: false,
    },
    {
      path: `/${locale}/mobile`,
      active: '/assets/icons/user-mobile-active.svg',
      defaultIcon: '/assets/icons/user-mobile.svg',
      isAuthProtected: true,
    },
  ]), [locale, cityPrefix]);

  return (
    <div className={s.mobile}>
      {paths.map(({
        path, active, defaultIcon, text, isAuthProtected,
      }) => (
        <Route
          key={path}
          path={path}
          active={active}
          defaultIcon={defaultIcon}
          text={text}
          isAuthProtected={isAuthProtected}
        />
      ))}
    </div>
  );
}
