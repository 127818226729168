import React, { useCallback } from 'react';
import s from '@/styles/layout/ModerAside.module.scss';
import useUserAccount from '@/hooks/users/useUserAccount';
import { useRouter } from 'next/router';
import Loading from '@/components/partials/Loading';
import { useAuthContext } from '@/modules/auth';

const URL = process.env.NEXT_PUBLIC_API_URL;

const menuItems = [
  { path: '/moderator/advs', icon: 'my_advs.svg', label: 'Объявления' },
  {
    path: '/moderator/categories',
    icon: 'icon_category.svg',
    label: 'Категории и подкатегории',
  },
  {
    path: '/moderator/users',
    icon: 'icon_user_notactive.svg',
    label: 'Пользователи',
  },
  { path: '/moderator/chat', icon: 'my_chat.svg', label: 'Чат' },
  { path: '/moderator/posters', icon: 'my_posters.svg', label: 'Мероприятия' },
  {
    path: '/moderator/moderation',
    icon: 'icon_moderator.svg',
    label: 'На модерации',
  },
  {
    path: '/moderator/security',
    icon: 'my_security.svg',
    label: 'Безопасность и вход',
  },
  {
    path: '/moderator/complaints',
    icon: 'icon_complaint.svg',
    label: 'Жалобы',
  },
  {
    path: '/moderator/deactivated',
    icon: 'icon_deactivate.svg',
    label: 'Деактивированные аккаунты',
  },
  {
    path: '/moderator/words',
    icon: 'forbidden_words.svg',
    label: 'Запрещенные слова',
  },
  {
    path: '/moderator/',
    icon: 'banner_icon.svg',
    label: 'Баннер на главной странице',
  },
];

function ModeratorAside() {
  const router = useRouter();
  const { userData, loading, error } = useUserAccount();
  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const navigate = useCallback(
    (path) => () => {
      router.push(path);
    },
    [router],
  );

  if (loading) return <Loading />;
  if (error) return <div>Error fetching user data!</div>;

  const renderMenuItem = ({ path, icon, label }) => {
    // Use the white icon if the current path matches the menu item's path.
    const isActive = router.pathname === path;
    const iconFileName = isActive ? icon.replace('.svg', '_white.svg') : icon;
    const iconPath = `/assets/${
      isActive ? 'white_icons' : 'icons'
    }/${iconFileName}`;

    return (
      <div
        key={path}
        className={isActive ? s.aside_link_menu_active : s.aside_link_menu}
        onClick={navigate(path)}
      >
        <img src={iconPath} alt={`${label}_icon`} />
        <p>{label}</p>
      </div>
    );
  };

  return (
    <section>
      <div className={s.moderator_aside}>
        <div className={s.aside_links}>
          <div
            className={
              router.pathname === '/moderator/account'
                ? s.active_moderator_ava
                : s.aside_link_moderator_ava
            }
            onClick={() => router.push('/moderator/account')}
          >
            <img
              src={
                userData.avatar
                  ? `${userData.avatar}`
                  : '/assets/icons/adv_contact_profile.svg'
              }
              alt="profile_icon"
            />
            <span>
              <h4>{userData.full_name}</h4>
              <p>Модератор</p>
            </span>
          </div>
          {menuItems.map(renderMenuItem)}
        </div>
        <div className={s.aside_btn_logout}>
          <button onClick={handleLogout}>
            Выйти из аккаунта
            <img src="/assets/icons/personal_logout.svg" alt="logout_icon" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default ModeratorAside;
