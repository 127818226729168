import { useParams } from 'next/navigation';
import Cookies from 'js-cookie';
import {
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { AuthApi } from '../api';
import { getTokenExpiration, cleanCookies } from './auth.helpers';


export const useLogout = () => {
  const params = useParams();

  return useCallback(() => {
    cleanCookies();
    window.location.href = `/${params?.locale || ''}`;
  }, [params?.locale]);
};


export const useWatchAuthTokens = (
  accessToken: string,
  setAccessToken: Dispatch<SetStateAction<string | undefined>>,
  refreshToken: string,
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const timeoutRef = useRef<number | undefined>();
  useEffect(() => () => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }
  }, []);
  useEffect(() => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    const fetchAccessToken = async () => {
      try {
        setIsLoading(true);
        const token = await AuthApi.refreshAccessToken(refreshToken);
        const exp = getTokenExpiration(token);
        Cookies.set('access_token', token, { expires: exp });
        setAccessToken(token);
      } catch (err) {
        if (err.response?.status === 401) {
          setAccessToken(undefined);
          setRefreshToken(undefined);
          cleanCookies();
        }
        throw err;
      } finally {
        setIsLoading(false);
      }
    };
    if (refreshToken) {
      timeoutRef.current = setTimeout(
        () => fetchAccessToken(),
        // a negative value will cause the function to be called immediately
        !accessToken ? 0 : +getTokenExpiration(accessToken) - Date.now() - 30000,
      ) as unknown as number;
    }
  }, [accessToken, refreshToken, setAccessToken, setIsLoading, setRefreshToken]);
};
