import React, { FC, ReactNode } from 'react';
import Link from 'next/link';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import s from './error-page.module.scss';

export const ErrorPage: FC<{ children: ReactNode }> = ({ children }) => {
  const { locale } = useRouter();
  return (
    <>
      <NextSeo title={i18n.notFoundPage.title[locale]} />
      <div className={s.container}>
        <div className={s.maskot}>
          <img src="/assets/mascots/fin-search.png" alt="404" />
        </div>
        <div className={s.text}>{children}</div>
        <div className={s.btns}>
          <Link href="/support" className={s.contact}>
            <img src="/assets/icons/phone2.svg" alt="contact" />
            {i18n.notFoundPage.contact[locale]}
          </Link>
          <Link href="/" className={s.home}>
            <img src="/assets/icons/home.svg" alt="home" />
            {i18n.notFoundPage.back[locale]}
          </Link>
        </div>
      </div>
    </>
  );
};
