import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { useRouter } from 'next/router';
import { getInitialCityInfo, setInitialCityInfo } from '@/modules/locations/helpers';

const GlobalContext = createContext();

function GlobalProvider({ children }) {
  const { query } = useRouter();

  const { city: initialCity, userSet } = getInitialCityInfo();

  const city = query?.city === '[city]' || !query?.city ? initialCity : query?.city;
  setInitialCityInfo({ city, userSet });

  const [homeCity, setHomeCity] = useState(initialCity);
  // чтобы поменялся контекст при переходе на страницу из адресной строки, например pangan/posters

  const [searchData, setSearchData] = useState([]);
  const [quantityAd, setQuantityAd] = useState([]);

  const value = useMemo(() => ({
    homeCity, setHomeCity, searchData, setSearchData, quantityAd, setQuantityAd,
  }), [homeCity, setHomeCity, searchData, setSearchData, quantityAd, setQuantityAd]);
  return (
    <GlobalContext.Provider
      value={value}
    >
      {children}
    </GlobalContext.Provider>
  );
}

const useGlobalData = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalData };
