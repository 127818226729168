import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import { redirectAfterLoginParam, modalTypeParam } from '@/modules/auth/consts';
import { useAuthContext } from '@/modules/auth/contexts';
import { cleanupSearch, buildQueryString } from '@/modules/auth/helpers';
import { removeLocaleFromUrl } from '@/modules/shared/helpers';
import { useCleanUrl } from './use-clean-url.hook';


const cleanupSearchParams = ['token', 'registration-type'];

const cleanCookies = () => {
  const cookies = [
    'forgotPasswordEmail',
    'resetPassword',
    'resetPassword2',
  ];
  for (const cookie in cookies) {
    if (Object.prototype.hasOwnProperty.call(cookies, cookie)) {
      Cookies.remove(cookie);
    }
  }
};

export const useCloseAuthModal = () => {
  const router = useRouter();
  const { pathname, search } = useCleanUrl();
  const { isAuthenticated } = useAuthContext();

  useEffect(() => cleanCookies(), []);

  return useCallback(() => {
    const redirectAfterLoginEncodedUrl = search.get(redirectAfterLoginParam);

    cleanupSearch(search, cleanupSearchParams);

    if (redirectAfterLoginEncodedUrl) {
      search.delete(redirectAfterLoginParam);
      if (isAuthenticated) {
        const decodedRedirectAfterLoginUrl = removeLocaleFromUrl(decodeURIComponent(redirectAfterLoginEncodedUrl));
        if (!decodedRedirectAfterLoginUrl.includes(modalTypeParam)) {
          router.push(`${decodedRedirectAfterLoginUrl}${buildQueryString(search)}`);
          return;
        }
      }
    }

    router.push(`${pathname}${buildQueryString(search)}`);
  }, [router, pathname, search, isAuthenticated]);
};
