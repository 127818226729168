import React, { FC, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import s from './input-password.module.scss';
import IconOpenPassword from './password-open.icon.svg';
import IconClosedPassword from './password-closed.icon.svg';

interface InputPasswordProps {
  control: Control;
  placeholder: string;
  name: string;
}

export const InputPassword: FC<InputPasswordProps> = ({
  control,
  placeholder,
  name,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const inputType = passwordShown ? 'text' : 'password';

  return (
    <div className={s.password}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <input
            {...field}
            type={inputType}
            placeholder={placeholder}
            className={s.password_input}
          />
        )}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className={s.password_eye}
      >
        {passwordShown ? <IconOpenPassword /> : <IconClosedPassword />}
      </button>
    </div>
  );
};
