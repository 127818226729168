import axios from 'axios';
import { api } from '@/modules/shared/api';
import Cookie from 'js-cookie';
import { endpoints } from './endpoints';
import { RegistrationType } from '../types';
import { getTokenExpiration } from '../providers/auth.helpers';

type LoginData = {
  email?: string;
  password?: string;
};

export const authAxios = axios.create({
  baseURL: api.defaults.baseURL,
  paramsSerializer: api.defaults.paramsSerializer,
});

export const AuthApi = {
  refreshAccessToken: async (refreshToken: string) => {
    const { data } = await api.post<{ access: string }>(
      endpoints.refreshAccessToken(),
      { refresh: refreshToken },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: '',
        },
      },
    );
    if (data.access) {
      const exp = getTokenExpiration(data.access);
      Cookie.set('access_token', data.access, { expires: exp });
    }

    return data.access;
  },

  login: async (loginData: LoginData) => {
    const { data } = await authAxios.post<{ access: string, refresh: string, id: string }>(
      endpoints.login(),
      loginData,
    );
    return data;
  },

  completeRegistration: async (
    { registrationType, ...body }: {
      registrationType: RegistrationType,
      totp?: string,
      token?: string
    },
  ) => {
    const { data } = await authAxios.put(
      endpoints.completeRegistration(),
      {
        registration_type: registrationType,
        ...body,
      },
    );
    return data;
  },

  resendActivationLink: async (email: string) => {
    await authAxios.post(
      endpoints.resendActivationLink(),
      { email },
    );
  },
};
