import React, {
  createContext, useState, useContext, useCallback,
} from 'react';

const CityPrefixContext = createContext();

export const useCityPrefix = () => useContext(CityPrefixContext);

export function CityPrefixProvider({ children }) {
  const [cityPrefix, setCityPrefix] = useState('allcities');

  // Оборачиваем setCityPrefix в useCallback для предотвращения создания новой функции при каждом рендере
  const handleSetCityPrefix = useCallback(
    (newPrefix) => {
      if (cityPrefix !== newPrefix) {
        setCityPrefix(newPrefix);
      }
    },
    [cityPrefix],
  );

  return (
    <CityPrefixContext.Provider
      value={{ cityPrefix, setCityPrefix: handleSetCityPrefix }}
    >
      {children}
    </CityPrefixContext.Provider>
  );
}
