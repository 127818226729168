import { api, uploadFileToS3 } from '@/modules/shared/api';
import { generateThumbnail } from '@/modules/shared/helpers';
import { endpoints } from './endpoints';
import { Me, IUpdateUserData } from '../types';

export const MeApi = {
  getMe: async () => {
    const { data } = await api.get<Me>(endpoints.me());
    return data;
  },
  updateUserData: async (newUserData: IUpdateUserData) => {
    const { data } = await api.patch(endpoints.me(), newUserData);
    return data;
  },
  updateUserAvatar: async (image: File, extension: string) => {
    const newAvatar = [];
    const { data } = await api.post(endpoints.uploadAvatar(), { format: extension });
    const thumbnail = await generateThumbnail(image);
    await uploadFileToS3(data.thumbnail_signed_url, thumbnail);
    await uploadFileToS3(data.avatar_signed_url, image);
    newAvatar.push(data);

    return newAvatar;
  },

  updateUserLanguage: async (updateLanguage: string) => {
    const { data } = await api.patch(
      endpoints.me(),
      { locale: updateLanguage },
    );
    return data;
  },
};
