import dynamic from 'next/dynamic';
import React, { FC, SVGProps } from 'react';
import styles from './svg-icon.module.scss';

type SvgIconProps = SVGProps<SVGSVGElement> & { name: string };

const DynamicSvg: FC<SvgIconProps> = ({ name, ...props }) => {
  const DynamicComponent = dynamic(() => import(`./svg/${name}.svg`), {
    loading: () => <svg {...props} />,
    ssr: false,
  });

  return <DynamicComponent {...props} />;
};

/**
 * This component is a temp replacement for https://mui.com/material-ui/icons/#svgicon,
 * because at the moment of writing this comment, our icons don't match the 24x24 size condition
 * The component reacts to css properties font-size and color
 */
export const SvgIcon: FC<SvgIconProps> = ({ className = '', ...props }) => {
  const resultedClassName = `${className} ${styles.icon}`;

  return (
    <DynamicSvg
      className={resultedClassName}
      {...props}
      data-testid={props['data-testid'] || props.name}
    />
  );
};
