'use client';

import React, {
  ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useRef,
} from 'react';
import { useConvertHeicToPng } from '@/modules/shared/helpers';
import { ActionButton } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { IUpdateUserData } from '../../types';


interface IProps {
  setEditedData: Dispatch<SetStateAction<IUpdateUserData>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
export const LoadAvatar: FC<IProps> = ({ setEditedData, setLoading }) => {
  const { t } = useTranslation('forms');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const convertHeicToPng = useConvertHeicToPng();

  const handleAvatarChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      const pngFile = file.type === 'image/heic' ? await convertHeicToPng(file) : file;
      setEditedData((current) => ({
        ...current,
        avatar: pngFile,
      }));
      setLoading(false);
    }
  }, [convertHeicToPng, setEditedData]);

  return (
    <>
      <input
        type="file"
        accept="image/*,.heic,.png,.jpeg,.jpg"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleAvatarChange}
      />
      <ActionButton
        onClick={() => fileInputRef.current?.click()}
        type="button"
      >
        {t('images.update')}
      </ActionButton>
    </>
  );
};
