import React from 'react';
import s from '@/styles/main/Buttons.module.scss';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useCityPrefix } from '@/context/CityPrefixContext';

export default function Cartaz() {
  const { locale } = useRouter();
  const { cityPrefix } = useCityPrefix();

  return (
    <Link href={`/${cityPrefix}/posters`}>
      <button className={s.cartaz_block}>
        <img src="/assets/icons/cartaz.svg" alt="cartaz" />
        <span>{i18n.header.events2[locale]}</span>
      </button>
    </Link>
  );
}
