import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { AuthApi } from '../api';
import { getTokenExpiration } from './auth.helpers';


export const useLoginMutation = (
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>,
  setAccessToken: Dispatch<SetStateAction<string | undefined>>,
) => {
  const {
    mutate, data, isPending, error, reset,
  } = useMutation({
    mutationFn: AuthApi.login,
  });

  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (data) {
      Cookies.set('access_token', data.access, { expires: getTokenExpiration(data.access) });
      setAccessToken(data.access);
      if (rememberMe) {
        Cookies.set('refresh_token', data.refresh, { expires: getTokenExpiration(data.refresh) });
        setRefreshToken(data.refresh);
      }
      Cookies.set('user_id', data.id, { expires: getTokenExpiration(data.access) });
    }
  }, [data, setRefreshToken, setAccessToken, rememberMe]);

  const handleLoginData = (loginData: { email: string, password: string, rememberMe: boolean }) => {
    setRememberMe(loginData.rememberMe);
    return mutate({ email: loginData.email, password: loginData.password });
  };

  const resetLoginError = useCallback(() => reset(), [reset]);

  return {
    login: handleLoginData,
    isLoading: isPending,
    error,
    resetLoginError,
  };
};
