import { useState } from 'react';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

export function useAuth() {
  const [loading, setLoading] = useState(false);

  const register = async (
    email,
    fullName,
    password,
    password2,
    phoneNumber,
    locale,
  ) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/api/users/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          full_name: fullName,
          password,
          password2,
          phone_number: phoneNumber,
          locale,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const err = new Error(`Failed to register: ${JSON.stringify(errorData)}`);
        if (errorData?.error_code) {
          err.code = errorData.error_code;
        }
        throw err;
      }

      return { response: await response.json(), error: null };
    } catch (err) {
      return { response: null, error: err };
    } finally {
      setLoading(false);
    }
  };

  return { register, loading };
}
