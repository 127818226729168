import React, { useRef, useState, useEffect } from 'react';
import s from '@/styles/main/Buttons.module.scss';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { isServerSide } from '@/modules/shared/helpers';
import CategoryListMobil from '../lists/СategoryListMobil';
import СategoryList from '../lists/СategoryList';

export default function CategoryButton() {
  const [categoryList, setCategoryList] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const listRef = useRef(null);
  const buttonRef = useRef(null);
  const { locale } = useRouter();

  useEffect(() => {
    if (!isServerSide()) {
      setIsMobile(window.innerWidth <= 700);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 700);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useOutsideClick(listRef, buttonRef, () => {
    if (categoryList) {
      setCategoryList(false);
    }
  });

  const handleCategoryList = () => {
    setCategoryList(!categoryList);
  };

  return (
    <div>
      <button
        ref={buttonRef}
        onClick={handleCategoryList}
        className={
          categoryList
            ? `${s.category_button_active} ${s.category_button}`
            : s.category_button
        }
      >
        <img
          src={
            categoryList
              ? '/assets/icons/exit.svg'
              : '/assets/icons/burgerbtn.svg'
          }
          alt="button"
        />
        <span>{i18n.header.category[locale]}</span>
      </button>
      {categoryList && isMobile && (
        <CategoryListMobil innerRef={listRef} onClose={handleCategoryList} />
      )}
      {categoryList && !isMobile && (
        <СategoryList innerRef={listRef} onClose={handleCategoryList} />
      )}
    </div>
  );
}
