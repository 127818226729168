import React, { useCallback, useEffect } from 'react';
import s from '@/styles/layout/UserSidebar.module.scss';
import useUserAccount from '@/hooks/users/useUserAccount';
import { useRouter } from 'next/router';
import Loading from '@/components/partials/Loading';
import i18n from '@/lib/i18n';
import { useAuthContext } from '@/modules/auth';

function UserSidebar() {
  const { userData, loading, error } = useUserAccount();
  const router = useRouter();
  const { logout } = useAuthContext();
  const { locale } = useRouter();

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      window.location.reload();
    }, 100);
    router.push('/');
  };

  const navigate = useCallback(
    (path) => () => {
      router.push(path);
    },
    [router],
  );

  const menuItems = [
    {
      path: '/user/advs',
      icon: 'my_advs.svg',
      label: i18n.header.dropdown.myAdv[locale],
    },
    {
      path: '/user/deactivate',
      icon: 'words.svg',
      label: i18n.user.deactivateAdv[locale],
    },
    {
      path: '/user/favorites',
      icon: 'my_favourites.svg',
      label: i18n.user.fav[locale],
    },
    {
      path: '/user/chat',
      icon: 'my_chat.svg',
      label: i18n.header.dropdown.chat[locale],
    },
    {
      path: '/user/posters',
      icon: 'my_posters.svg',
      label: i18n.header.dropdown.myEvents[locale],
    },
    {
      path: '/user/deactivated-posters',
      icon: 'words.svg',
      label: i18n.sidebars.deactivateEvents[locale],
    },
    {
      path: '/user/security',
      icon: 'my_security.svg',
      label: i18n.sidebars.security[locale],
    },
  ];

  if (loading || (!userData && !error)) return <Loading />;
  if (error) return <div>Error fetching user data!</div>;

  const renderMenuItem = ({ path, icon, label }) => {
    const isActive = router.pathname === path;
    const iconFileName = isActive ? icon.replace('.svg', '_white.svg') : icon;
    const iconPath = `/assets/${
      isActive ? 'white_icons' : 'icons'
    }/${iconFileName}`;

    return (
      <div
        key={path}
        className={isActive ? s.aside_link_menu_active : s.aside_link_menu}
        onClick={navigate(path)}
      >
        <img src={iconPath} alt={`${label}_icon`} />
        <p>{label}</p>
      </div>
    );
  };

  return (
    <section className={s.block_personal_account}>
      <div className={s.personal_account_aside}>
        <div className={s.aside_links}>
          <div
            className={
              router.pathname === '/user/account'
                ? s.active_user_ava
                : s.aside_link_user_ava
            }
            onClick={() => router.push('/user/account')}
          >
            <img
              src={
                userData.avatar
                  ? `${userData.avatar}`
                  : '/assets/images/sceleton-ava.png'
              }
              alt="profile_icon"
            />
            <span>
              <h4>{userData.full_name}</h4>
              <p>{i18n.header.dropdown.user[locale]}</p>
            </span>
          </div>

          {menuItems.map(renderMenuItem)}
        </div>
        <div className={s.aside_btn_logout}>
          <button onClick={handleLogout}>
            {i18n.header.dropdown.logout[locale]}
            <img src="/assets/icons/personal_logout.svg" alt="logout_icon" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default UserSidebar;
