import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './notFound.module.scss';

export const NotFound = () => {
  const { t } = useTranslation('notFound');
  return (
    <div className={styles.found}>
      <img src="/assets/images/cry.png" alt="cry" />
      <h2>{t('firstText')}</h2>
      <h3>{t('secondText')}</h3>
    </div>
  );
};
