import React, { useEffect } from 'react';
import { useCloseAuthModal } from '../../hooks';
import { LoginModal } from './login-modal.component';
import RegisterModal from './register-modal.component';
// the following component can be used in future
// import RegisterCodeModal from './register-code-modal.component';
import { ForgotPasswordModal } from './forgot-password-modal.component';
import CodeModal from './code-modal.component';
import ResetPasswordModal from './reset-password-modal.component';
import styles from './auth-modal.module.scss';
import { RegisterConfirmationModal } from './register-confirmation-modal.component';
import { PasswordChangeSuccessModal } from './password-change-success-modal.component';
import { CompleteRegistrationModal } from './complete-registration-modal.component';
import { useModalType } from './auth-modal.hooks';
import { ResendActivationLinkModal } from './resend-activation-link-modal.component';

const modals = {
  register: RegisterModal, // the sign up modal
  registerConfirmation: RegisterConfirmationModal, // "confirm your sign up" modal
  /**
   * handles the account activation link from the email
   */
  completeRegistration: CompleteRegistrationModal,
  resendActivationLink: ResendActivationLinkModal,

  login: LoginModal,
  forgot: ForgotPasswordModal,
  reset: ResetPasswordModal,
  code: CodeModal,
  passwordSuccess: PasswordChangeSuccessModal,
};

export function AuthModal() {
  const modalType = useModalType();
  const Modal = modals[modalType];
  const closeModal = useCloseAuthModal();

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    if (Modal) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (Modal) {
        document.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [Modal, closeModal]);

  return !Modal ? null : (
    <div className={styles.container}>
      <div className={styles.modal_background} onClick={closeModal} />
      <Modal />
    </div>
  );
}
