import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { buildListQueryKeyFactory } from './helpers';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

export const fetchInfiniteListFactory = (listApi) => async ({ pageParam, queryKey }) => {
  const [filters, order, pageSize] = queryKey;
  return listApi({
    url: pageParam,
    ...(pageParam ? {} : { order, filters, pageSize }),
  });
};


const getNextPageParam = (lastPage) => {
  try {
    const url = new URL(lastPage.next);
    /**
     * Заново строим урл до апи с использованием хоста из API_URL
     * Если этого не делать, при работе через local-proxy.js будет проставляться хост после 301 редиректа
     */
    return `${API_URL}${url.pathname}${url.search}`;
  } catch (e) {
    return lastPage.next;
  }
};


/**
 *
 * @param {*} listApi
 * @param {string[]} baseQueryKey
 */
export const infiniteListQueryFactory = (listApi, baseQueryKey) => {
  const fetchData = fetchInfiniteListFactory(listApi);
  const buildQueryKey = buildListQueryKeyFactory(baseQueryKey);

  const useInfiniteListQuery = (options) => {
    const { disabled = false } = options;
    const queryKey = buildQueryKey(options);

    const {
      data,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      error,
      refetch,
    } = useInfiniteQuery({
      queryKey,
      queryFn: fetchData,
      getNextPageParam,
      enabled: !disabled,
      refetchOnWindowFocus: false,
    });

    return useMemo(() => ({
      hasMore: hasNextPage,
      isLoading: isFetchingNextPage || isLoading,
      fetchMore: fetchNextPage,
      pages: data?.pages || [],
      error,
      refetch,
    }), [
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      fetchNextPage,
      data?.pages,
      error,
      refetch,
    ]);
  };

  return useInfiniteListQuery;
};
