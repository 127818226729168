
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import Head from 'next/head';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Layout from '@/components/shared/layout/Layout';
import { CityPrefixProvider } from '@/context/CityPrefixContext';
import '@/styles/globals.scss';
import { CategoriesProvider, withPreloadedCategories } from '@/modules/categories';
import { AdvDataProvider } from '@/context/AdvListContext';
import { EventsTrackingProvider } from '@/modules/events-tracking';
import { setupQueryClient, useCheckVersion } from '@/modules/app';
import { AuthProvider, useAuthContext } from '@/modules/auth';
import { MeProvider } from '@/modules/me';
import { StyledEngineProvider } from '@mui/material/styles';
const queryClient = setupQueryClient();
function App({ Component, pageProps, preloadedCategories }) {
    useCheckVersion();
    const { isAuthenticated } = useAuthContext();
    return (<QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId="1093979259932-crrapug660vsftv9pclko1o2nata4kgu.apps.googleusercontent.com">
        <AuthProvider>
          <MeProvider isAuthenticated={isAuthenticated}>
            <CityPrefixProvider>
              <CategoriesProvider initData={preloadedCategories}>
                <AdvDataProvider>
                  <StyledEngineProvider injectFirst>
                    <Head>
                      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
                    </Head>
                    <Layout>
                      <EventsTrackingProvider />
                      <ToastContainer />
                      <Component {...pageProps}/>
                    </Layout>
                  </StyledEngineProvider>
                </AdvDataProvider>
              </CategoriesProvider>
            </CityPrefixProvider>
          </MeProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>);
}
const __Next_Translate__Page__191bdc73004__ = withPreloadedCategories(App);

    export default __appWithI18n(__Next_Translate__Page__191bdc73004__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  