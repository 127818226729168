import { parsePhoneNumberFromString, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';

export const exampleFormatPhoneNumber = (countryCode) => {
  const phoneNumber = parsePhoneNumberFromString(countryCode || '');
  if (phoneNumber) {
    const exampleNumber = getExampleNumber(phoneNumber.country, examples);
    return exampleNumber.formatInternational();
  }
  return '';
};
