import React, { ComponentProps } from 'react';
import Link from 'next/link';
import { omit } from 'lodash';
import { useAuthContext } from '../contexts';
import { useAuthModalUrl } from '../hooks';

export const AuthProtectedLink = (props: ComponentProps<typeof Link>) => {
  const { href } = props;
  const authModalLink = useAuthModalUrl(href);
  const { isAuthenticated } = useAuthContext();

  const requiresAuth = href !== '/';
  const path = !isAuthenticated && requiresAuth ? authModalLink : href;

  return <Link href={path} {...omit(props, 'href')} />;
};
