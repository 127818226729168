import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useRef } from 'react';

export const useSetQueryParams = () => {
  const routerRef = useRef(useRouter());
  const pathname = usePathname();
  const searchParams = useSearchParams();

  return useCallback((params) => {
    const newParams = {};
    for (const [key, value] of searchParams.entries()) {
      newParams[key] = value;
    }
    for (const key of Object.keys(params)) {
      if (params[key] === undefined || params[key] === null || params[key] === '') {
        delete newParams[key];
      } else {
        newParams[key] = params[key];
      }
    }
    routerRef.current.push({
      pathname,
      query: newParams,
    });
  }, [pathname, searchParams]);
};
