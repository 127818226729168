import React, { useEffect, useCallback } from 'react';
import s from '@/styles/main/AuthModal.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { useSearchParams } from 'next/navigation';
import { useMutation } from '@tanstack/react-query';
import { useTrackEvent } from '@/modules/events-tracking';
import { useOpenAuthModal } from '@/modules/auth/hooks';
import { AuthApi } from '../../api';
import { RegistrationType } from '../../types';
import { AuthProtectedLink } from '../auth-protected-link.component';
import { ActionButton } from './action-button.component';


export const CompleteRegistrationModal = () => {
  const { t } = useTranslation('');
  const setModalType = useOpenAuthModal();
  const searchParams = useSearchParams();
  const token = searchParams.get('token');
  const registrationType = searchParams.get('registration-type') as RegistrationType;

  const { isPending, error, mutateAsync } = useMutation({
    mutationFn: AuthApi.completeRegistration,
  });

  const getTitle = (isLoading: boolean, errorMsg: boolean) => {
    if (isLoading) {
      return t('auth:completeRegistration.loading');
    }
    if (errorMsg) {
      return t('auth:completeRegistration.error');
    }
    return t('auth:completeRegistration.success');
  };

  const handleSendEmailAgain = useCallback(() => {
    setModalType('resendActivationLink');
  }, [setModalType]);

  const trackEvent = useTrackEvent();
  useEffect(() => {
    const completeRgistrtaion = async () => {
      try {
        await mutateAsync({ token, registrationType });
        trackEvent('register');
      } catch (e) {
        console.error(e);
      }
    };
    completeRgistrtaion();
  }, [token, registrationType, mutateAsync, trackEvent]);
  const success = !isPending && !error;



  return (
    <div className={s.auth_modal}>
      <div className={s.register_completed}>
        <h2>{getTitle(isPending, !!error)}</h2>
        <div className={s.completed_container}>
          {!success ? null : (
            <>
              <AuthProtectedLink href="/" className={s.completed_container_homepage}>
                { t('auth:label.homepage')}
              </AuthProtectedLink>
              <AuthProtectedLink href="/user/account" className={s.completed_container_account}>
                {t('auth:label.account')}
              </AuthProtectedLink>
            </>
          )}
          {!error ? null : (
            <ActionButton
              type="button"
              onClick={handleSendEmailAgain}
            >
              {t('auth:label.sendEmailAgain')}
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  );
};
