import React, { useState } from 'react';
import s from '@/styles/main/AuthModal.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { useOpenAuthModal } from '@/modules/auth/hooks';
import { useActivationTimer } from '@/modules/auth/helpers';
import { ActivationTimer } from '../activation-timer.component';
import { ActionButton } from './action-button.component';

export const RegisterConfirmationModal = () => {
  const { t } = useTranslation('');
  const setModalType = useOpenAuthModal();
  const { isButtonDisabled } = useActivationTimer();
  const [buttonDisabled, setButtonDisabled] = useState(isButtonDisabled);

  const handleClick = () => {
    localStorage.setItem(
      'authModalState',
      JSON.stringify({ modalType: 'login', email: '' }),
    );
    setModalType('login');
  };

  return (
    <div className={s.auth_modal} onClick={handleClick}>
      <div className={s.register_confirmation} onClick={(event) => event.stopPropagation()}>
        <h2>{t('auth:registerConfirmation.title')}</h2>
        <p>{t('auth:registerConfirmation.description')}</p>
        <form>
          <ActionButton
            type="button"
            disabled={buttonDisabled}
            onClick={() => setModalType('resendActivationLink')}
          >
            {t('auth:label.sendEmailAgain')}
            {' '}
            {buttonDisabled && <ActivationTimer handleTimerEnd={() => setButtonDisabled(false)} />}
          </ActionButton>
        </form>
      </div>
    </div>
  );
};
